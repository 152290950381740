<script lang="ts" setup>
interface SnackbarProps {
    title?: string
    message?: string
    type?: 'error' | 'success' | 'warning'
    closable?: boolean
    iconName?: string
}

const props = withDefaults(defineProps<SnackbarProps>(), {
    type: 'success',
})

const $style = useCssModule()
const classes = computed(() => {
    return [
        $style.root,
        props.type && $style[`root--${props.type}`],
    ]
})

const iconName = computed(() => {
    if (props.iconName) return props.iconName
    else if (props.type === 'success') return 'check-line'
    else if (props.type === 'warning') return 'bell'
    else if (props.type === 'error') return 'warning'

    return undefined
})

const isDisplayed = defineModel<boolean>({ default: true })
const onClose = () => (isDisplayed.value = false)
</script>

<template>
    <div
        v-if="isDisplayed"
        :class="classes"
    >
        <div :class="$style.icon">
            <VIcon
                v-if="iconName"
                view-box="0 0 24 24"
                :name="iconName"
            />
        </div>
        <div>
            <p
                v-if="title"
                :class="$style.title"
                class="text-h4 bold"
            >
                {{ title }}
            </p>
            <VMarkdown
                v-if="message"
                inline
                :class="$style.message"
                :content="message"
                class="text-body-xs"
            />
            <slot />
        </div>
        <VButton
            v-if="props.closable"
            :class="$style.close"
            tertiary
            :title="$t('close')"
            icon-name="close-small"
            @click.prevent="onClose"
        />
    </div>
</template>

<style lang="scss" module>
.root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-2-xs);
    border: 1px solid var(--snackbar-color);
    border-radius: var(--radius-md);
    margin: var(--spacing-2-xs) 0;
    background: var(--color-surfaces-primary);
    color: var(--snackbar-color);
    gap: var(--spacing-2-xs);

    --color-label-tertiary: var(--snackbar-color);

    &--error {
        --snackbar-color: var(--color-error);
    }

    &--warning {
        --snackbar-color: var(--color-warning);
    }

    &--success {
        --snackbar-color: var(--color-success);
    }

    @include media('>=md') {
        padding: var(--spacing-sm);
    }
}

.title {
    margin-bottom: rem(4);
}

.message {
    max-width: 70ch;
}

.icon {
    display: flex;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background-color: color-mix(in srgb, var(--snackbar-color) 20%, transparent);
}

.close {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-left: auto;
    background-color: transparent;
    cursor: pointer;
    justify-self: flex-end;
}
</style>
